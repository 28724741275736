:root {
  --wheel-size: 319px;
  --wheel-border-size: 0px;
  --wheel-color: #da3768;
  --PI: 3.14159265358979;
  --nb-item: 0;
  --item-nb: 0;
  --selected-item: 0;
  --nb-turn: 5;
  --spinning-duration: 6s;
  --reset-duration: 0.25s;
}

.wheel-container {
  display: block;
  position: relative;
  box-sizing: content-box;
  /* width: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
  height: calc(var(--wheel-size) + 2 * var(--wheel-border-size)); */
  padding: 3px;
  margin: auto;
  user-select: none;
}

.wheel {
  display: block;
  position: relative;
  box-sizing: content-box;
  margin: auto;
  /* width: var(--wheel-size);
  height: var(--wheel-size); */
  overflow: hidden;
  /* border-radius: 50%;
  border: solid transparent var(--wheel-border-size);
  outline: solid #9B59B6 3px; */
  /* animation: blink 1s;
  animation-iteration-count: 3; */
  transition: transform var(--reset-duration);
  transform: rotate(0deg);
}

.wheel.spinning {
  transition: transform var(--spinning-duration);
  transform: rotate(calc(var(--nb-turn) * 360deg + (-360deg * var(--selected-item) / var(--nb-item, 1))));
}

/* .wheel::after {
  display: block;
  position: absolute;
  content: '';
  width: 25px;
  height: 25px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
} */

.spinner-tick {
  position: absolute;
  display: block;
  z-index: 9;
}

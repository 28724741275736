.board-container {
  background: #27133A;
  border: 1px solid #9521C9;
  box-shadow: inset 0px 0px 10px rgba(248, 115, 251, 0.5);
  border-radius: 12px;
}

.new-board-container {
  border-radius: 12px;
  border: 1px solid #9521C9;
  background: var(--Background-Purple-Dark, #330253);
  box-shadow: 0px 0px 10px 0px rgba(248, 115, 251, 0.50) inset;
}
